import React, { useEffect, useState } from "react";
import config from "../config/config";

const RecentTransactionnModal = (props) => {
  var transHash = props.transactionHash;

  const hideModal = async () => {
    window.$("#withdraw_success_modal").modal("hide");
    window.$("#confirm_modal").modal("hide");
  };

  return (
    <div
      className="modal fade primary_modal"
      id="withdraw_success_modal"
      tabIndex={-1}
      aria-labelledby="withdraw_success_modal_modalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="withdraw_success_modalLabel">
              Successfully Purchased
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={hideModal}
            ></button>
          </div>
          <div className="modal-body">
            <div className="buytoken_confirm_body buytoken_confirm_body_chg">
              <div className="justify-content-center mt-4">
                <svg
                  className="success"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                >
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
                {/* <a href={config.txurl + transHash} target="_blank" className="d-block mt-3"> <i className="fa fa-eye" aria-hidden="true">
                        </i> View Transaction </a> */}
                <a
                  className="d-flex mt-4 justify-content-center"
                  href={config.txurl + transHash}
                  target="_blank"
                >
                  {" "}
                  <i className="fa fa-eye mt-1" aria-hidden="true"></i> View
                  Transaction{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentTransactionnModal;
